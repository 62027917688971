<template>
  
  <div style="max-width:1200px;margin: 0 auto;padding: 0 20px; position: relative; top: -20px; text-shadow: 1px 1px 2px black">
    <h1 style="text-decoration: underline; display: block; text-align: center; font-size: 28px">DOCUMENTACIÓN</h1>
    <br />
    <div style="display: inline-block; padding-top: 6px; zoom: 90%;float:right">
      <h4><a href="#about1">1. ¿Que es CONWORDS?</a></h4>
      <h4><a href="#about2">2. Como jugar.</a></h4>
      <h4><a href="#about3">3. Como crear crucigramas.</a></h4>
      <h4><a href="#about4">4. Como crear diccionarios.</a></h4>
      <h4><a href="#about5">5. Próximos pasos.</a></h4>
    </div>
    <!-- <h4><a href="#about5">5. Atribución</a></h4> -->

    <h2 id="about1" style="clear: both;">1. ¿Qué es CONWORDS?</h2>
    <p>Es un juego y un editor de crucigramas colaborativo (desarrollado por <a target="_blank" style="margin-right: 0;" href="https://github.com/fabnun">@fabnun</a>), donde puedes jugar, crear y compartir crucigramas.</p><br>
    
    <p>Te invitamos a probar la versión Beta, tus comentarios serán muy apreciados, esperamos comenzar a formar una gran comunidad.</p><br>
    
    <p>Creemos que permitir a la comunidad crear y compartir crucigramas, mejorará la calidad de los puzzles, haciéndolos más entretenidos, más colaborativos y más temáticos.</p><br>

    <p>Cualquier consulta la puedes hacer en <a href="mailto:conwords.app@gmail.com">conwords.app@gmail.com</a> o en:<br><br> <Social></Social></p>
    <br />
    <h2 id="about2">2. Como jugar.</h2>
    <div style="max-width: 360px;margin:0 auto">
      <div class="videowrapper">
        <iframe width="315" height="560" src="https://www.youtube.com/embed/i_RKUUlSae8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <h2 id="about3">3. Como crear crucigramas.</h2>
    <p>Próximamente...</p>
    <!-- <div style="max-width: 720px">
      <div class="videowrapper">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/EColTNIbOko" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div> -->
    <h2 id="about4">4. Como crear diccionarios.</h2>
    <p>Próximamente...</p>
    <br>
    <!-- <div style="max-width: 720px">
      <div class="videowrapper">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/EColTNIbOko" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div> -->
    <p>Diccionarios de ejemplo:</p>
    <a href="https://conwords.app/diccionarios.csv.1.zip" target="_blank">En Formato csv con separador ","</a><br />
    <a href="https://conwords.app/diccionarios.csv.2.zip" target="_blank">En Formato csv con separador ";"</a><br />
    <a href="https://conwords.app/diccionarios.json.zip" target="_blank">En Formato json</a><br />
    <br />
    <p>Fuentes de datos:</p>
    <a target="_blank" href="https://portalfrases.com/dichos-chilenos-populares/">Dichos Chilenos</a>
    <a target="_blank" href="https://www.apocatastasis.com/chilenismos-diccionario.php">Jerga Coa</a>
    <a target="_blank" href="http://www.tjmm.com/trivial/paginatrivial.htm">Trivial IRC</a>
    <a target="_blank" href="https://en.wiktionary.org/wiki/User:Matthias_Buchmeier#Spanish-English">Diccionarios de traducción</a>
    <a target="_blank" href="http://www.omegawiki.org/Special:Ow_downloads">Mas diccionarios</a>
    <a target="_blank" href="https://archive.org/details/diccionario-de-sinonimos-antonimos-y-paronimos1">Sinónimos y Antónimos</a>
    <a target="_blank" href="https://github.com/oprogramador/most-common-words-by-language">Palabras comunes</a>
    <a target="_blank" href="https://www.mapuche.nl/espanol/idioma/index_idioma.htm">Diccionario Mapudungun</a>

    <h2 id="about5">5. Próximos pasos.</h2>

    <p>
      <br>
      Posiblemente, se implementarán algunas de las siguientes características:<br>
      <br>
      <ul style="zoom:1.1">
        <li>Mostrar una tabla de posiciones para cada crucigrama. Y una tabla de posiciones general, separando los que juegan en modo FÁCIL y los que juegan en modo MÁSTER.</li>
        <li>Cada pregunta y cada crucigrama se podrá comentar públicamente,  además se podrá reportar si se estima que no es adecuado, permitiendo la eliminación o modificación.</li>
        <li>Cada jugador que lo desee, podrá tener una página con su perfil público, donde puede elegir mostrar su clasificación, sus crucigramas completados, sus crucigramas creados, sus comentarios, etc. y además dar la opción de agregar como amigo o reportar.
        </li><li>Copiar preguntas desde los crucigramas hacia diccionarios, con el fin de practicar esas preguntas en crucigramas propios.</li>
        <li>Detener el cronómetro cuando se configura el juego, cuando está navegando y cuando la app o la página no este activa.</li>
        <li>Se podrá obtener una versión imprimible de cada crucigrama en diferentes estilos.</li>
        <li>Se podrá programar la publicación de un crucigrama, y los jugadores que jueguen en el momento de la publicación podrán ver en línea el avance del juego. Esto permitirá hacer competencias o torneos después.</li>
        <li>Autogenerar diccionarios con preguntas del tipo "completar frase", ingresando solo las frases y un algoritmo se encargará de eliminar las stopwords y para generar luego las preguntas con las palabras restantes.</li>
        <li>Los tags se editarán en los diccionarios (actualmente se editan en el generador de crucigramas). Esto permitirá clasificar bien los crucigramas y poder indicar el porcentaje de cada tags en la generación de un crucigrama.</li>
        <li>Mejorar el algoritmo de generación de Crucigramas (Evaluar ejecutarlo en máquinas virtuales de servicios cloud).</li>
        <li>Usar web workers en la generación de crucigramas, para evitar el bloqueo de la interfaz.</li>
        <li>Editar manualmente los crucigramas: Agregar o quitar preguntas, configurar preguntas con imagen, video o audio. Personalizar imagen de fondo y entrega de resultados.</li>
        <li>Si el proyecto comienza a ser masivo, se implementará algún mecanismo para financiar el gasto en servidores cloud, pudiendo ser servicios prémium, anuncios, donaciones, etc., y con las ganancias se retribuirá a los usuarios generadores de crucigramas más populares.</li>
      </ul>
    </p>

    <h2 id="about5">6. Versión para Android</h2>
    <p>
      <br>
      <a href="/apk/conwords.0.8.2.apk" target="_blank">conwords.0.8.2.apk</a>
    </p>

    <!-- <h2 id="about5">5. Atribución</h2>
    <h3>SOUNDS</h3>
    <a href="https://freesound.org/people/rhodesmas/sounds/320652/">sound1</a>
    <a href="https://freesound.org/people/18hiltc/sounds/201847/">sound2</a>
    <a href="https://freesound.org/people/Kenneth_Cooney/sounds/609335/">sound3</a>
    <a href="https://freesound.org/people/EminYILDIRIM/sounds/536108/">sound4</a>
    <a href="https://freesound.org/people/xtraplex/sounds/121585/">sound5</a>
    <a href="https://freesound.org/people/Unlistenable/sounds/391540/">sound6</a>
    <a href="https://freesound.org/people/Sjonas88/sounds/538554/">sound7</a>
    <a href="https://freesound.org/people/Enok123/sounds/157766/">sound8</a>
    <a href="https://freesound.org/people/Tetoszka/sounds/538013/">sound9</a>
    <a href="https://freesound.org/people/waveplaySFX/sounds/222057/ ">sound10</a>
        
    replace snd1 with these sounds
    https://freesound.org/people/SplatFreeSound/sounds/413690/
    https://freesound.org/people/InspectorJ/sounds/403019/
    https://freesound.org/people/martcraft/sounds/651625/
    https://freesound.org/people/nomiqbomi/sounds/578390/

    <h3>FONTS</h3>
    <a href="https://fonts.bunny.net/family/aldrich">aldrich</a><a href="https://fonts.bunny.net/family/ubuntu-mono">ubuntu-mono</a>
    <h3>GIFS</h3>
    <a href="https://gifer.com/en/2r69">confetti</a><br /> -->

    <br>
    <br>
  </div>
</template>
<script>
import Social from '../components/Social.vue';
export default {
  name: 'About',
  components: {
    Social,
  },
  mounted: function() {
    this.setLoading(false);
  },
};
</script>

<style scoped>
a {
  text-decoration: underline;
  margin-right: 8px;
  margin-bottom: 0px;
  font-size: 100%;
  color: rgb(255, 231, 14);
  display: inline-block;
}
h1 {
  padding-top: 42px;
  font-size: 32px;
  text-decoration: underline;
}
h2 {
  padding-top: 42px;
  font-size: 22px;
  text-decoration: underline;
}
h3 {
  padding-top: 22px;
  font-size: 18px;
}
h4 {
  padding-top: 0px;
  margin-bottom: 0px;
  font-size: 18px;
}
p {
  font-size: 18px;
}

ul.done li {
  text-decoration: line-through;
  color: red;
}
.videowrapper {
  float: none;
  clear: both;
  width: 100%;

  position: relative;
  padding-bottom: 178.25%;
  padding-top: 25px;
  height: 0;
  background: url(/img/youtube-load.gif) no-repeat center center;
  background-color: #111;
  border-radius: 12px;
  margin: 12px 0 32px;
}
.videowrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid var(--color-texto);
  border-radius: 12px;
}
li {
  margin-bottom: 12px;
}
</style>
