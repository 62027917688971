<template>
  <div class="social">
    <a target="_blank" href="https://twitter.com/ConWordsApp">
      <ThemifyIcon icon="twitter" />
    </a>
    <a target="_blank" href="https://www.facebook.com/conwordspage">
      <ThemifyIcon icon="facebook" />
    </a>
    <a target="_blank" href="https://instagram.com/ConWordsApp">
      <ThemifyIcon icon="instagram" />
    </a>
    <a target="_blank" href="https://www.tiktok.com/@conwordsapp">
      <img src="/img/tiktok.svg" style="width: 24px; padding: 2px 0" alt="" />
    </a>
    <a target="_blank" href="https://www.youtube.com/@conwordsapp">
      <ThemifyIcon icon="youtube" />
    </a>
    <!-- <a target="_blank" href="https://discord.com/invite/Vz3WdYuy">
      <img src="/img/discord.svg" style="width: 24px; padding: 3px 0" alt="" />
    </a> -->
  </div>
</template>
<script>
import ThemifyIcon from 'vue-themify-icons';
export default {
  name: 'Social',
  components: {
    ThemifyIcon,
  },
};
</script>
<style scoped>
.social {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  font-size: 64px;
}
.social a i {
  position: relative;
  top: 3px;
  font-size: 24px;
}
.social a {
  text-shadow: none;
  background-color: var(--color-select);
  color: black;
  font-weight: bolder;
  padding: 8px 10px;
  border-radius: 50%;
  margin: 4px;
}
</style>
